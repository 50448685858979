import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NetworkChangeView from '../views/NetworkChange.vue'
import PrivilegeRequestView from '../views/PrivilegeRequest.vue'
import IncidentReportView from '../views/IncidentReport.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/networkchange',
    name: 'networkchange',
    component: NetworkChangeView
  },
  {
    path: '/privilegerequest',
    name: 'privilegerequest',
    component: PrivilegeRequestView
  },
  {
    path: '/incidentreport',
    name: 'incidentreport',
    component: IncidentReportView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
