<template>
  <img class = "logo" alt="WSP Logo" src="./assets/wsplogo.png">
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/networkchange">Network Change</router-link> |
    <router-link to="/privilegerequest">Privilege Request</router-link> |
    <router-link to="/incidentreport">Incident Report</router-link>
  </nav>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

img {
  max-width:100%;
}

nav {
  padding-bottom: 50px;
  
}

nav a {
  font-weight: bold;
  color: #000000;
}

nav a.router-link-exact-active {
  color: #11b319;
}
</style>
